import { Sheet, Typography } from "@mui/joy";
import { Link, useLocation } from "react-router-dom";
import Button from "@mui/joy/Button";

export const WebNavBar = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <>
      <div style={{ display: "block", width: "100%", height: 70 }}></div>
      <Sheet className={"mobile-nav-container web-nav"}>
        <Link
          to={"/overzicht"}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            style={{
              height: 55,
            }}
            src={"/charlie.png"}
          />
          <img
            style={{
              height: 40,
            }}
            src={"/cvog-horizontal.svg"}
          />
        </Link>
        <Link
          to={"/overzicht"}
          className={
            "web-nav-item" +
            (currentPath.includes("/overzicht") ? " nav-active" : "")
          }
        >
          <Typography level={"body-md"}>Overzicht</Typography>
        </Link>
        <Link
          className={
            "web-nav-item" +
            (currentPath.includes("/scholen") ? " nav-active" : "")
          }
          to={"/scholen"}
        >
          <Typography level={"body-md"}>Scholen</Typography>
        </Link>
        <Link
          className={
            "web-nav-item" +
            (currentPath.includes("/lessen") ? " nav-active" : "")
          }
          to={"/lessen"}
        >
          <Typography level={"body-md"}>Lessen</Typography>
        </Link>
        <Link
          to={"/profiel"}
          className={
            "web-nav-item" +
            (currentPath.includes("/profiel") ? " nav-active" : "")
          }
        >
          <Typography level={"body-md"}>Mijn profiel</Typography>
        </Link>
        <Button
          onClick={() => {
            localStorage.removeItem("userToken");
            window.location.href = "/login";
          }}
          variant={"outlined"}
          color={"danger"}
        >
          Uitloggen
        </Button>
      </Sheet>
    </>
  );
};
