import {
  Avatar,
  Button,
  ButtonGroup,
  CardActions,
  Chip,
  SvgIcon,
  Typography,
} from "@mui/joy";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import CardOverflow from "@mui/joy/CardOverflow";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import { useEffect, useState } from "react";
import { animateBack, animateToCenter } from "../utils/animation";
import {
  getApplicationStatus,
  getMyApplicationStatus,
} from "../../actions/applicant-calls";
import { determineQualificationFromKey } from "../utils/utils";

export const ApplicantCard = ({ user, course, accept, remove }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [applicationStatus, setApplicationStatus] = useState({});
  const closeModal = () => {
    setModalOpen(false);
    animateBack(user.id);
  };

  const openModal = () => {
    setModalOpen(true);
    animateToCenter(user.id);
  };

  useEffect(() => {
    console.log(applicationStatus, course);
  }, [applicationStatus]);

  useEffect(() => {
    if (course && modalOpen) {
      getApplicationStatus(setApplicationStatus, course.id, user.id);
    }
  }, [course, modalOpen]);

  return (
    <div style={{ zIndex: modalOpen ? 10002 : 1 }}>
      {modalOpen && <div onClick={closeModal} className={"modal-container"} />}

      <Card
        style={{ margin: "auto" }}
        sx={{
          transition: "all 0.5s ease-in-out",
          width: modalOpen ? 500 : 320,
          maxWidth: "100%",
          boxShadow: "lg",
          maxHeight: modalOpen ? "fit-content" : 400,
          minHeight: 400,
        }}
        className={"clickable-div-" + user.id}
      >
        <CardContent sx={{ alignItems: "center", textAlign: "center" }}>
          <Avatar src={user.profile_picture} sx={{ "--Avatar-size": "4rem" }} />
          <Chip
            size="sm"
            variant="soft"
            color="primary"
            sx={{
              mt: -1,
              mb: 1,
              border: "3px solid",
              borderColor: "background.surface",
            }}
          >
            {user.title}
          </Chip>
          <div style={{ display: "flex", flexDirection: "row", gap: 1 }}>
            {user.qualifications.map((quali) => {
              return (
                <Chip
                  size="sm"
                  variant="soft"
                  color="warning"
                  sx={{
                    mt: -1,
                    mb: 1,
                    border: "2px solid",
                    borderColor: "background.surface",
                  }}
                >
                  {determineQualificationFromKey(quali)}
                </Chip>
              );
            })}
          </div>
          <Typography level="title-lg">
            {user.firstname} {user.lastname}
          </Typography>
          <Typography
            level="body-sm"
            sx={{ maxWidth: modalOpen ? "50ch" : "24ch" }}
          >
            {modalOpen
              ? user.title_explanation
              : user.title_explanation && user.title_explanation.length > 150
              ? user.title_explanation.substring(0, 150) + "..."
              : user.title_explanation}
          </Typography>
        </CardContent>
        <CardOverflow sx={{ bgcolor: "background.level1" }}>
          <CardActions buttonFlex="1">
            <ButtonGroup
              variant="outlined"
              sx={{ bgcolor: "background.surface" }}
            >
              {modalOpen ? (
                <>
                  <Button onClick={closeModal}>Annuleren</Button>
                  <Button
                    disabled={!!course.teacher && course.teacher.id !== user.id}
                    variant={"solid"}
                    color={applicationStatus.accepted ? "danger" : "success"}
                    onClick={() => {
                      if (applicationStatus.accepted) {
                        closeModal();
                        remove(course.id, user.id);
                      } else {
                        closeModal();
                        accept(course.id, user.id);
                      }
                    }}
                  >
                    {!applicationStatus.accepted
                      ? "Uitnodigen"
                      : course.teacher && course.teacher.id !== user.id
                      ? "Bezet"
                      : "Intrekken"}
                  </Button>
                </>
              ) : (
                <>
                  <Button onClick={() => window.open("mailto:" + user.email)}>
                    Email
                  </Button>
                  <Button onClick={openModal}>Bekijk</Button>
                </>
              )}
            </ButtonGroup>
          </CardActions>
        </CardOverflow>
      </Card>
    </div>
  );
};
