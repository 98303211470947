import {
  Button,
  CssBaseline,
  FormControl,
  FormLabel,
  Input,
  Link,
  Sheet,
  Typography,
} from "@mui/joy";
import { useEffect, useState } from "react";
import {
  registerUserWith2FA,
  retrieveInvite,
  setup2FA,
} from "../../actions/auth-calls";
import { ErrorToast } from "../utils/alerts";
import "./auth.css";
import { useParams } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";

export const SignUp = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [invite, setInvite] = useState({});
  const [error, setError] = useState();
  const { inviteKey } = useParams();
  const [secret, setSecret] = useState("");

  useEffect(() => {
    retrieveInvite(inviteKey, setInvite).then();
  }, []);

  useEffect(() => {
    setUsername(invite.email);
  }, [invite]);

  return (
    <main className={"auth-main"}>
      <div>
        <video
          className={"auth-video"}
          playsInline={true}
          muted={true}
          autoPlay={true}
          loop={true}
        >
          <source src={"/cvog.mp4"} />
        </video>
      </div>
      <CssBaseline />
      <Sheet
        sx={{
          width: 300,
          mx: "auto",
          my: "auto",
          py: 4,
          px: 5,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          borderRadius: "sm",
          boxShadow: "xl",
          alignItems: secret ? "center" : "",
        }}
        variant="outlined"
      >
        <div>
          <Typography
            level="h4"
            sx={{ color: "#272D2D !important" }}
            component="h1"
          >
            <b>Welkom bij Charlie!</b>
          </Typography>
          <Typography level="body-sm">Log in om door te gaan.</Typography>
        </div>
        {!secret && (
          <form
            style={{ display: "flex", flexDirection: "column", gap: 10 }}
            onSubmit={async (e) => {
              e.preventDefault();
              if (password !== password2) {
                setError("Wachtwoorden zijn niet gelijk");
                return;
              }
              if (password.length < 8) {
                setError("Je wachtwoord moet minimaal 8 tekens lang zijn");
                return;
              }
              const secret = await setup2FA(username);
              setSecret(secret);
            }}
          >
            <FormControl>
              <FormLabel>E-mail</FormLabel>
              <Input
                value={username}
                readOnly={true}
                onChange={(e) => setUsername(e.target.value)}
                name="email"
                placeholder="johndoe@email.com"
                color={error && "danger"}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Wachtwoord</FormLabel>
              <Input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                type="password"
                placeholder="wachtwoord"
                color={error && "danger"}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Wachtwoord herhalen</FormLabel>
              <Input
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
                name="password2"
                type="password"
                placeholder="wachtwoord herhalen"
                color={error && "danger"}
              />
            </FormControl>
            <Button type={"submit"} sx={{ mt: 1 }}>
              2FA instellen
            </Button>
          </form>
        )}
        {secret && (
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              const elements = e.target.elements;
              const code = elements.code2fa.value;
              const { token, role } = await registerUserWith2FA(
                username,
                password,
                inviteKey,
                secret.secret,
                code
              );
              if (token === "error") {
                setError("Foutieve inloggegevens");
              } else {
                localStorage.setItem("userToken", token);
                let search = new URLSearchParams(window.location.search);
                if (role && role === "Administrator")
                  window.location.href = "/dashboard";
                else if (search.has("ref")) {
                  window.location.href = search.get("ref");
                } else {
                  window.location.href = "/profiel";
                }
              }
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 5,
              width: "100%",
            }}
          >
            <Typography level={"body-sm"} sx={{ textAlign: "center" }}>
              Scan deze qr-code met je authenticator app
            </Typography>
            <QRCodeSVG
              style={{
                width: 150,
                height: 150,
                marginLeft: "auto",
                marginRight: "auto",
                margin: 5,
              }}
              value={secret.url}
            />
            <FormControl required={true} sx={{ mt: 1 }}>
              <FormLabel>Code</FormLabel>
              <Input
                name="code2fa"
                onInput={(e) => {
                  const target = e.target;
                  target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                slotProps={{
                  input: {
                    minLength: 6,
                    maxLength: 6,
                    pattern: "[0-9]*",
                    inputMode: "numeric",
                  },
                }}
                type="text"
                size={"sm"}
                placeholder="2FA code"
              />
            </FormControl>
            <Button size={"sm"} sx={{ width: 176 }} type={"submit"}>
              Opslaan
            </Button>
          </form>
        )}

        <Typography
          endDecorator={<Link href="/login">Inloggen</Link>}
          fontSize="sm"
          sx={{ alignSelf: "center" }}
        >
          Al een account?
        </Typography>
      </Sheet>
      <ErrorToast error={error} setError={setError} />
    </main>
  );
};
