import axios from "axios";
import { keys } from "../config/keys.dev";

export const handleLogin = async (username, password, code) => {
  const response = await axios.post(keys.url + `/api/login/`, {
    username,
    password,
    code,
  });
  let token = response.data?.token || "error";
  let role = response.data?.role || "error";
  if (response.data.error && response.data.error.includes("2fa")) {
    token = "2fa";
    role = "2fa";
  }
  if (response.data.error && response.data.error.includes("code")) {
    token = "error-code";
    role = "error-code";
  }
  return { token, role };
};

export const registerUserWith2FA = async (
  username,
  password,
  inviteKey,
  secret,
  code
) => {
  const response = await axios.post(keys.url + `/api/signup/`, {
    username,
    password,
    inviteKey,
    secret,
    code,
  });
  let token = response.data?.token || "error";
  let role = response.data?.role || "error";
  return { token, role };
};

export const add2FaToUser = async (username, password, secret, code) => {
  const response = await axios.post(keys.url + `/api/add_2fa/`, {
    username,
    password,
    secret,
    code,
  });
  let token = response.data?.token || "error";
  let role = response.data?.role || "error";
  return { token, role };
};

export const setup2FA = async (email) => {
  const response = await axios.post(keys.url + `/api/generate_2fa_secret/`, {
    email,
  });
  return response.data;
};

export const validateLogin = async (email, password) => {
  const response = await axios.post(keys.url + "/api/validate_login/", {
    email,
    password,
  });
  return {
    success: response.data.success,
    completed2FA: response.data.completed_2fa,
  };
};

export const retrieveInvite = async (inviteKey, setInvite) => {
  const response = await axios.get(keys.url + `/api/invite/${inviteKey}/`);
  setInvite(response.data);
};

export const handleLogout = async () => {
  const response = await axios.get(keys.url + "/api/logout/");
  localStorage.removeItem("userToken");
  window.location.href = "/login";
};
