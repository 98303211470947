import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Divider from "@mui/joy/Divider";
import Stack from "@mui/joy/Stack";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Textarea from "@mui/joy/Textarea";
import CardOverflow from "@mui/joy/CardOverflow";
import CardActions from "@mui/joy/CardActions";
import Button from "@mui/joy/Button";
import {
  BadgeRounded,
  Check,
  Close,
  Euro,
  InfoOutlined,
  LocationCityRounded,
  Money,
  SchoolRounded,
} from "@mui/icons-material";
import Card from "@mui/joy/Card";
import React, { useEffect, useState } from "react";
import { Select } from "@mui/joy";
import Option from "@mui/joy/Option";
import FormHelperText from "@mui/joy/FormHelperText";
import {
  deleteCourse,
  retrieveCourseCreation,
  saveCourse,
} from "../../../actions/course-calls";
import { useParams } from "react-router-dom";
import {
  getCourseTypes,
  getQualifications,
  getCoursePreferences,
} from "../../../actions/util-calls";
import { getSchools, getAdminSchool } from "../../../actions/school-calls";
import { ErrorToast } from "../../../components/utils/alerts";

export const CreateCourse = () => {
  const { courseId } = useParams("courseId");
  const formatToISOStringInLocalTimezone = (date) => {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, -8);
  };
  const [courseName, setCourseName] = useState("");
  const [groupName, setGroupName] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [type, setType] = useState("teach");
  const [qualification, setQualification] = useState("geen");
  const [school, setSchool] = useState("");
  const [paymentMultiplier, setPaymentMultiplier] = useState(1.0);
  const [errors, setErrors] = useState({});
  const [titleLoading, setTitleLoading] = useState(false);
  const [titleCancelled, setTitleCancelled] = useState(false);
  const [titleSaved, setTitleSaved] = useState(false);
  const [titleCancelling, setTitleCancelling] = useState(false);
  const [course, setCourse] = useState({});
  const [adminSchool, setAdminSchool] = useState({});
  const [courseTypes, setCourseTypes] = useState([]);
  const [qualifications, setQualifications] = useState([]);
  const [schools, setSchools] = useState([]);
  const [error, setError] = useState("");
  const [coursePreferences, setCoursePreferences] = useState([]);

  useEffect(() => {
    setSchool(adminSchool.id);
  }, [adminSchool]);

  useEffect(() => {
    getCourseTypes(setCourseTypes);
    getQualifications(setQualifications);
    getCoursePreferences(setCoursePreferences);
    getAdminSchool(setAdminSchool);
    getSchools(setSchools);
    if (courseId) {
      retrieveCourseCreation(
        courseId,
        (course) => {
          setCourse(course);
          setCourseName(course.courseName);
          setGroupName(course.groupName);
          setDescription(course.description);
          setStartDate(
            formatToISOStringInLocalTimezone(new Date(course.startDate))
          );
          setEndDate(
            formatToISOStringInLocalTimezone(new Date(course.endDate))
          );
          setSchool(course.school);
          setType(course.type);
          setQualification(course.qualification);
          setPaymentMultiplier(course.paymentMultiplier);
        },
        setError
      );
    }
  }, []);

  useEffect(() => {
    if (!!startDate && !endDate) {
      setEndDate(
        formatToISOStringInLocalTimezone(
          new Date(new Date(startDate).getTime() + 3600000)
        )
      );
    }
  }, [startDate]);

  const resetFields = (field) => {
    setTitleCancelling(true);
    setCourseName(course.courseName);
    setGroupName(course.groupName);
    setDescription(course.description);
    setStartDate(formatToISOStringInLocalTimezone(new Date(course.startDate)));
    setEndDate(formatToISOStringInLocalTimezone(new Date(course.endDate)));
    setSchool(course.school);
    setType(course.type);
    setQualification(course.qualification);
    setPaymentMultiplier(course.paymentMultiplier);
    setErrors({});
    setTimeout(() => {
      setTitleCancelling(false);
      setTitleCancelled(true);
      setTimeout(() => setTitleCancelled(false), 1000);
    }, 1000);
  };
  const saveFields = (field) => {
    if (titleLoading) return;
    setTitleLoading(true);
    console.log("Trying to save course");
    const courseToSave = {
      ...course,
      courseName,
      groupName,
      description,
      startDate,
      endDate,
      school,
      type,
      qualification,
      paymentMultiplier,
    };
    setCourse((oldCourse) => {
      const newCourse = {
        ...oldCourse,
        courseName,
        groupName,
        description,
        startDate,
        endDate,
        school,
        type,
        qualification,
        paymentMultiplier,
      };
      console.log("Saving course");

      return newCourse;
    });
    saveCourse(courseToSave, (course) => {
      window.location.href = "/dashboard/courses/create/" + course.id;
    });
    setTimeout(() => {
      setTitleLoading(false);
      setTitleSaved(true);
      setTimeout(() => setTitleSaved(false), 1000);
    }, 1000);
  };

  return (
    <Box
      sx={{
        width: "calc(100vw - 356px)",
        left: 256,
        padding: "40px",
        position: "relative",
        minWidth: 300,
        display: "flex",
        flexDirecthion: "column",
        alignItems: "center",
        paddingBottom: 8,
      }}
    >
      <Stack
        spacing={4}
        sx={{
          display: "flex",
          maxWidth: "100%",
          width: { xs: "90%", md: "90%" },
          px: { xs: 2, md: 6 },
          py: { xs: 2, md: 3 },
        }}
      >
        <Card>
          <Box sx={{ mb: 1 }}>
            <Typography level="title-md">Les</Typography>
            <Typography level="body-sm">
              Vul alle gegevens van de les in.
            </Typography>
          </Box>
          <Divider />
          <Stack spacing={2} sx={{ my: 1 }}>
            <FormControl errors={!!errors.courseName}>
              <FormLabel>Les naam</FormLabel>
              {/* <Input
                size="sm"
                placeholder={"Duits, Frans, Nederlands..."}
                value={courseName}
                onChange={(e) => setCourseName(e.target.value)}
              /> */}
              <Select
                size="sm"
                value={courseName}
                onChange={(e, val) => setCourseName(val)}
                startDecorator={<SchoolRounded />}
              >
                {coursePreferences.map((ct) => {
                  return <Option value={ct.name}>{ct.name}</Option>;
                })}
              </Select>
              <FormHelperText
                style={{ display: errors.courseName ? "block" : "none" }}
              >
                <InfoOutlined />
                Foutieve waarde gevonden
              </FormHelperText>
            </FormControl>
            <FormControl errors={!!errors.description}>
              <FormLabel>Les omschrijving</FormLabel>
              <Textarea
                placeholder={
                  "Welke taken moet de invaller uitvoeren? \n\nWaar kan de invaller de lesmaterialen vinden?"
                }
                size="sm"
                minRows={4}
                maxRows={4}
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <FormHelperText
                style={{ display: errors.description ? "block" : "none" }}
              >
                <InfoOutlined />
                Foutieve waarde gevonden
              </FormHelperText>
            </FormControl>
            <FormControl>
              <FormLabel>Klas naam</FormLabel>
              <Input
                size="sm"
                placeholder={"H4B"}
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
              />
              <FormHelperText
                style={{ display: errors.groupName ? "block" : "none" }}
              >
                <InfoOutlined />
                Foutieve waarde gevonden
              </FormHelperText>
            </FormControl>
            <FormControl error={!!errors.type}>
              <FormLabel>Opdracht type</FormLabel>
              {courseTypes.length > 0 && (
                <Select
                  size="sm"
                  value={type}
                  onChange={(e, val) => setType(val)}
                  startDecorator={<SchoolRounded />}
                >
                  {courseTypes.map((ct) => {
                    return <Option value={ct.key}>{ct.name}</Option>;
                  })}
                </Select>
              )}
              <FormHelperText
                style={{ display: errors.type ? "block" : "none" }}
              >
                <InfoOutlined />
                Foutieve waarde gevonden
              </FormHelperText>
            </FormControl>
            <FormControl error={!!errors.qualification}>
              <FormLabel>Bevoegdheden</FormLabel>
              {qualifications.length > 0 && (
                <Select
                  size="sm"
                  startDecorator={<BadgeRounded />}
                  value={qualification}
                  onChange={(e, val) => setQualification(val)}
                >
                  {qualifications.map((q) => {
                    return <Option value={q.key}>{q.name}</Option>;
                  })}
                </Select>
              )}
              <FormHelperText
                style={{ display: errors.qualification ? "block" : "none" }}
              >
                <InfoOutlined />
                Foutieve waarde gevonden
              </FormHelperText>
            </FormControl>
            <FormControl error={!!errors.school}>
              <FormLabel>School</FormLabel>
              {schools.length > 0 && (
                <Select
                  size="sm"
                  disabled={true}
                  startDecorator={<LocationCityRounded />}
                  value={school}
                  onChange={(e, val) => setSchool(val)}
                >
                  {schools.map((s) => {
                    return <Option value={s.id}>{s.name}</Option>;
                  })}
                </Select>
              )}
              <FormHelperText
                style={{ display: errors.school ? "block" : "none" }}
              >
                <InfoOutlined />
                Foutieve waarde gevonden
              </FormHelperText>
            </FormControl>
            <FormControl error={!!errors.startDate || !!errors.endDate}>
              <FormLabel>Datum</FormLabel>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                  alignItems: "center",
                }}
              >
                <FormLabel sx={{ alignSelf: "center", marginBottom: 0 }}>
                  Begin les:
                </FormLabel>
                <Input
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  type={"datetime-local"}
                  slotProps={{ input: { step: 300 } }}
                />
                <FormLabel sx={{ alignSelf: "center", marginBottom: 0 }}>
                  Einde les:
                </FormLabel>
                <Input
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  type={"datetime-local"}
                />
              </div>
              <FormHelperText
                style={{
                  display:
                    errors.startDate || errors.endDate ? "block" : "none",
                }}
              >
                <InfoOutlined />
                Foutieve waarde gevonden
              </FormHelperText>
            </FormControl>
            <FormControl error={!!errors.paymentMultiplier}>
              <FormLabel>Vergoeding vermenigvuldiger</FormLabel>
              <Input
                size="sm"
                type={"number"}
                slotProps={{ input: { min: 0.0, max: 3.0, step: 0.01 } }}
                startDecorator={<Money />}
                value={paymentMultiplier}
                onChange={(e) =>
                  setPaymentMultiplier(parseFloat(e.target.value))
                }
              />
              <FormHelperText
                style={{ display: errors.paymentMultiplier ? "block" : "none" }}
              >
                <InfoOutlined />
                Foutieve waarde gevonden
              </FormHelperText>
            </FormControl>
          </Stack>
          <CardOverflow sx={{ borderTop: "1px solid", borderColor: "divider" }}>
            <CardActions
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                pt: 2,
              }}
            >
              <Button
                disabled={!courseId}
                size="sm"
                variant="solid"
                onClick={() => {
                  if (
                    window.confirm(
                      "Weet u zeker dat u deze les wil verwijderen"
                    )
                  ) {
                    deleteCourse(
                      course.id,
                      () =>
                        (window.location.href = "/dashboard/courses/overview")
                    );
                  }
                }}
                color={titleCancelled ? "danger" : "danger"}
                loading={titleCancelling}
                startDecorator={titleCancelled && <Close />}
                className={"color-button"}
              >
                {titleCancelled ? "Geannuleerd" : "Verwijderen"}
              </Button>
              <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                <Button
                  disabled={titleLoading}
                  size="sm"
                  variant="outlined"
                  onClick={() => resetFields("title")}
                  color={titleCancelled ? "warning" : "neutral"}
                  loading={titleCancelling}
                  startDecorator={titleCancelled && <Close />}
                  className={"color-button"}
                >
                  {titleCancelled ? "Geannuleerd" : "Annuleren"}
                </Button>
                <Button
                  loading={titleLoading}
                  size="sm"
                  variant="solid"
                  onClick={() => saveFields("title")}
                  color={titleSaved ? "success" : "primary"}
                  startDecorator={titleSaved && <Check />}
                  className={"color-button"}
                >
                  {titleSaved ? "Opgeslagen" : "Opslaan"}
                </Button>
              </div>
            </CardActions>
          </CardOverflow>
        </Card>
      </Stack>
      <ErrorToast error={error} setError={setError} />
    </Box>
  );
};
