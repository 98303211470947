import { Typography } from "@mui/joy";
import CardContent from "@mui/joy/CardContent";
import Card from "@mui/joy/Card";
import { EmailOutlined } from "@mui/icons-material";
import Button from "@mui/joy/Button";
import CardOverflow from "@mui/joy/CardOverflow";
import CardActions from "@mui/joy/CardActions";
import { timeUntil } from "../../utils/utils";
import { keys } from "../../../config/keys.dev";

export const AssignedCard = ({ step, course, school }) => {
  let disabled = false;
  if (step < 3) disabled = true;
  const timeLeft = timeUntil(course?.date);
  return (
    <Card
      className={"apply-card"}
      style={disabled ? { filter: "blur(2px)" } : { borderColor: "#0b6bcb" }}
    >
      <Typography level={"title-sm"}>Les geven</Typography>
      <CardContent>
        <Typography>Je bent nu helemaal klaar om de les te geven.</Typography>
        <Typography>Zorg dat je {timeLeft}.</Typography>
        <Typography>
          Bij{" "}
          <b>
            <a
              style={{
                color: "#000",
                pointerEvents: disabled ? "none" : "auto",
              }}
              target={"_blank"}
              href={keys.web + "/school/" + course?.school.id}
            >
              {course?.school.name}
            </a>
          </b>{" "}
          bent
        </Typography>
      </CardContent>
      <CardOverflow>
        <CardActions>
          <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
            <Button
              disabled={disabled}
              onClick={() => {
                window.location.href = "mailto:" + school.email;
              }}
              color={"neutral"}
              startDecorator={<EmailOutlined />}
            >
              Contact met de school
            </Button>

            <Button
              disabled={disabled}
              onClick={() => {
                window.location.href = "mailto:" + course.admin_email;
              }}
              color={"primary"}
              startDecorator={<EmailOutlined />}
            >
              Contact met de lesuitzetter
            </Button>
          </div>
        </CardActions>
      </CardOverflow>
    </Card>
  );
};
