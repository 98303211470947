import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import {
  acceptInvitation,
  declineInvitation,
  getApplication,
  getMyApplicationStatus,
} from "../../actions/applicant-calls";
import { CourseCard } from "../cards/CourseCard";
import { AppliedCard } from "./cards/AppliedCard";
import { AssignedCard } from "./cards/AssignedCard";
import { AcceptedCard } from "./cards/AcceptedCard";
import "./application.css";
import { MessageContext } from "../../contexts/Contexts";
import { apply } from "../../actions/course-calls";
import { getSchool } from "../../actions/school-calls";

export const Application = () => {
  const { courseId } = useParams("courseId");
  const { setMessage } = useContext(MessageContext);
  const [application, setApplication] = useState({});
  const [school, setSchool] = useState({});

  const [applicationStatus, setApplicationStatus] = useState({});
  useEffect(() => {
    getApplication(setApplication, courseId);
    getMyApplicationStatus(setApplicationStatus, courseId);
  }, [courseId]);

  useEffect(() => {
    if (application && application.course) {
      getSchool(application.course.school.id, setSchool);
    }
  }, [application]);
  console.log(application);
  console.log(school);

  let step = 0;
  if (applicationStatus.applied) {
    step = 1;
  }
  if (applicationStatus.accepted) {
    step = 2;
  }
  if (applicationStatus.assigned) {
    step = 3;
  }
  return (
    <div className={"application-container"}>
      {application && application.course && (
        <CourseCard large={true} course={application.course} />
      )}
      <div className={"application-status-container"}>
        <AppliedCard
          step={step}
          apply={() =>
            apply(courseId, () => {
              getMyApplicationStatus(setApplicationStatus, courseId);
            })
          }
          cancelApply={() =>
            apply(courseId, () => {
              getMyApplicationStatus(setApplicationStatus, courseId);
            })
          }
        />
        <AcceptedCard
          step={step}
          declineInvite={() => {
            if (
              window.confirm(
                "Weet je zeker dat je de uitnodiging wil afwijzen?"
              )
            ) {
              declineInvitation(application.course.id).then(() => {
                setMessage({
                  text: "Uitnodiging afgewezen",
                  color: "danger",
                });
                getMyApplicationStatus(
                  setApplicationStatus,
                  application.course.id
                );
              });
            }
          }}
          acceptInvite={() =>
            acceptInvitation(application.course.id).then(() => {
              setMessage({
                text: "Uitnodiging geaccepteerd!",
                color: "success",
              });
              getMyApplicationStatus(
                setApplicationStatus,
                application.course.id
              );
            })
          }
        />
        <AssignedCard step={step} course={application.course} school={school} />
      </div>
    </div>
  );
};
