import {
  Button,
  CssBaseline,
  FormControl,
  FormLabel,
  Input,
  Link,
  Sheet,
  Typography,
} from "@mui/joy";
import { useState } from "react";
import {
  handleLogin,
  validateLogin,
  add2FaToUser,
  setup2FA,
} from "../../actions/auth-calls";
import { ErrorToast } from "../utils/alerts";
import "./auth.css";
import { QRCodeSVG } from "qrcode.react";

export const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [show2FA, setShow2FA] = useState(false);
  const [code, setCode] = useState("");
  const [secret, setSecret] = useState("");
  const [error, setError] = useState();
  return (
    <main className={"auth-main"}>
      <div>
        <video
          className={"auth-video"}
          playsInline={true}
          muted={true}
          autoPlay={true}
          loop={true}
        >
          <source src={"/cvog.mp4"} />
        </video>
      </div>
      <CssBaseline />
      <Sheet
        sx={{
          width: 300,
          mx: "auto",
          my: "auto",
          py: 4,
          px: 5,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          borderRadius: "sm",
          boxShadow: "xl",
        }}
        variant="outlined"
      >
        <div>
          <Typography
            level="h4"
            sx={{ color: "#272D2D !important" }}
            component="h1"
          >
            <b>Welkom bij Charlie!</b>
          </Typography>
          <Typography level="body-sm">Log in om door te gaan.</Typography>
        </div>
        <form
          style={{ display: "flex", flexDirection: "column", gap: 10 }}
          onSubmit={async (e) => {
            e.preventDefault();
            let validateLoginResult = await validateLogin(username, password);
            if (
              validateLoginResult.success &&
              validateLoginResult.completed2FA
            ) {
              setShow2FA(true);
            } else if (
              validateLoginResult.success &&
              !validateLoginResult.completed2FA
            ) {
              setShow2FA(true);
              const secret = await setup2FA(username);
              setSecret(secret);
            }
          }}
        >
          {!show2FA && (
            <>
              <FormControl>
                <FormLabel>E-mail</FormLabel>
                <Input
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  name="email"
                  placeholder="johndoe@email.com"
                  color={error && "danger"}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Wachtwoord</FormLabel>
                <Input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  name="password"
                  type="password"
                  placeholder="password"
                  color={error && "danger"}
                />
              </FormControl>
              <Button type={"submit"} sx={{ mt: 1 }}>
                Log in
              </Button>
            </>
          )}
        </form>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            const { token, role } = await handleLogin(username, password, code);
            if (token === "error") {
              setError("Foutieve inloggegevens");
            } else if (token === "error-code") {
              setError("Foutieve 2fa code");
            } else if (token === "2fa") {
              setError("2FA is verplicht");
              const secret = await setup2FA(username);
              setSecret(secret);
            } else {
              localStorage.setItem("userToken", token);
              let search = new URLSearchParams(window.location.search);
              if (role && role === "Administrator")
                window.location.href = "/dashboard";
              else if (search.has("ref")) {
                window.location.href = search.get("ref");
              } else {
                window.location.href = "/overzicht";
              }
            }
          }}
        >
          {show2FA && !secret && (
            <>
              <FormControl sx={{ mt: 1, mb: 1 }}>
                <FormLabel>2FA verificatie code</FormLabel>
                <Input
                  onInput={(e) => {
                    const target = e.target;
                    target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  slotProps={{
                    input: {
                      minLength: 6,
                      maxLength: 6,
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                    },
                  }}
                  name={"code2fa"}
                ></Input>
              </FormControl>
              <Button type={"submit"}>Bevestigen</Button>
            </>
          )}
        </form>
        {secret && (
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              const elements = e.target.elements;
              const code = elements.code2fa.value;
              const { token, role } = await add2FaToUser(
                username,
                password,
                secret.secret,
                code
              );
              if (token === "error") {
                setError("Foutieve inloggegevens");
              } else {
                localStorage.setItem("userToken", token);
                let search = new URLSearchParams(window.location.search);
                if (role && role === "Administrator")
                  window.location.href = "/dashboard";
                else if (search.has("ref")) {
                  window.location.href = search.get("ref");
                } else {
                  window.location.href = "/profiel";
                }
              }
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 5,
              width: "100%",
            }}
          >
            <Typography level={"body-sm"} sx={{ textAlign: "center" }}>
              Scan deze qr-code met je authenticator app
            </Typography>
            <QRCodeSVG
              style={{
                width: 150,
                height: 150,
                marginLeft: "auto",
                marginRight: "auto",
                margin: 5,
              }}
              value={secret.url}
            />
            <FormControl required={true} sx={{ mt: 1 }}>
              <FormLabel>Code</FormLabel>
              <Input
                name="code2fa"
                onInput={(e) => {
                  const target = e.target;
                  target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                slotProps={{
                  input: {
                    minLength: 6,
                    maxLength: 6,
                    pattern: "[0-9]*",
                    inputMode: "numeric",
                  },
                }}
                type="text"
                size={"sm"}
                placeholder="2FA code"
              />
            </FormControl>
            <Button size={"sm"} sx={{ width: 176 }} type={"submit"}>
              Opslaan
            </Button>
          </form>
        )}
        {!(show2FA && !secret) && (
          <Typography
            endDecorator={<Link href="/sign-up">Registreren</Link>}
            fontSize="sm"
            sx={{ alignSelf: "center" }}
          >
            Geen account?
          </Typography>
        )}
      </Sheet>
      <ErrorToast error={error} setError={setError} />
    </main>
  );
};
