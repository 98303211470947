import * as React from "react";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Input,
  Sheet,
  Typography,
} from "@mui/joy";
import "./course.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { apply, getApplied, getCourse } from "../../actions/course-calls";
import {
  AccessTime,
  Add,
  ArrowBack,
  CalendarMonth,
  CoffeeOutlined,
  Remove,
  RestaurantOutlined,
  TrainOutlined,
} from "@mui/icons-material";
import { CongratCard } from "../cards/CongratCard";
import { Modal } from "../utils/Modal";
import AspectRatio from "@mui/joy/AspectRatio";
import { getSchool } from "../../actions/school-calls";
import IconButton from "@mui/joy/IconButton";

export const Course = () => {
  const [course, setCourse] = useState({});
  const [loading, setLoading] = useState(false);
  const [applied, setApplied] = useState({});
  const [cardOpen, setCardOpen] = useState(false);
  const [school, setSchool] = useState({});
  const { courseId } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    getCourse(courseId, (course) => {
      getSchool(course.school.id, setSchool);
      setCourse(course);
    });
    getApplied(courseId, setApplied);
  }, []);

  const applyForCourse = () => {
    if (!course.is_teaching) {
      setLoading(true);
      apply(courseId, (newApplied) => {
        setCardOpen(true);
        setApplied(newApplied);
        setTimeout(() => setLoading(false), 500);
        setTimeout(() => getApplied(courseId, setApplied), 500);
      });
    } else {
      window.alert("Neem contact op met de school om je af te melden");
    }
  };
  let card;
  if (cardOpen) {
    card = (
      <Modal setOpen={setCardOpen}>
        <CongratCard
          title={applied ? "Aanmelding verstuurd" : "Aanmelding ingetrokken"}
          content={
            applied
              ? "Je aanmelding op deze les is verstuurd, je krijgt via je email een reactie op je aanmelding!"
              : "Je aanmelding is ingetrokken, van gedachten veranderd? Je kan je altijd weer opnieuw aanmelden"
          }
          setOpen={setCardOpen}
        />
      </Modal>
    );
  }

  return (
    <div className={"school-container"}>
      {card}
      <IconButton
        onClick={() => {
          navigate(-1);
        }}
        sx={{
          display: { md: "none", xs: "flex" },
          borderRadius: "50%",
          size: "lg",
          backgroundColor: "#FFF",
          position: "fixed",
          zIndex: 999999,
          left: 25,
          top: 25,
          width: 50,
          height: 50,
          "--Icon-color": "#272D2D",
        }}
        disabled={false}
        variant="outlined"
      >
        <ArrowBack />
      </IconButton>
      {course && course.school && (
        <Card
          orientation={"horizontal"}
          sx={{
            width: { xs: "90%", md: "70%" },
            padding: 0,
            flexWrap: { xs: "wrap", md: "nowrap" },
          }}
        >
          <AspectRatio flex ratio="1" maxHeight={182} sx={{ minWidth: 182 }}>
            <img src={school.school_picture} loading="lazy" alt="" />
          </AspectRatio>
          <CardContent sx={{ p: 2 }}>
            <Typography
              level="body-sm"
              fontWeight="lg"
              textColor="text.tertiary"
            >
              {school.type}
            </Typography>
            <Typography fontSize="xl" fontWeight="lg">
              {school.name}
            </Typography>
            <Sheet
              sx={{
                bgcolor: "background.level1",
                borderRadius: "sm",
                p: 1.5,
                my: 1.5,
                display: "flex",
                gap: 2,
                "& > div": { flex: 1 },
              }}
            >
              <div>
                <Typography level="body-xs" fontWeight="lg">
                  Lessen
                </Typography>
                <Typography fontWeight="lg">{school.total_courses}</Typography>
              </div>
              <div>
                <Link
                  to={"/school/" + school.id}
                  style={{ textDecoration: "none" }}
                >
                  <Typography level="body-xs" fontWeight="lg">
                    Open lessen
                  </Typography>
                  <Typography fontWeight="lg">{school.open_courses}</Typography>
                </Link>
              </div>
            </Sheet>
            <Button
              variant="solid"
              onClick={() => {
                window.open("mailto:" + school.email, "_blank");
              }}
              color="primary"
              disabled={!school.email}
            >
              Contact
            </Button>
          </CardContent>
        </Card>
      )}
      <Divider orientation={"horizontal"} sx={{ mt: 1, mb: 1 }} />
      <div className={"course-submit-container"}>
        <Card variant={"outlined"} sx={{ p: 2 }}>
          <div className={"school-title-container"}>
            <Typography level={"h2"}>{course.name}</Typography>
            <Typography
              level="body-lg"
              fontWeight="lg"
              textColor="text.tertiary"
            >
              {course.class_name}
            </Typography>
          </div>

          <Divider />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 20,
            }}
          >
            <CoffeeOutlined style={{ fontSize: 36 }} />
            <div>
              <Typography fontWeight={"bold"}>Gratis koffie & thee</Typography>
              <Typography level={"body-sm"}>
                Bij deze school is gratis koffie & thee verkrijgbaar
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 20,
            }}
          >
            <RestaurantOutlined style={{ fontSize: 36 }} />
            <div>
              <Typography fontWeight={"bold"}>Toegang tot kantine</Typography>
              <Typography level={"body-sm"}>
                Bij deze school is een kantine waar je eten kan halen
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 20,
            }}
          >
            <TrainOutlined style={{ fontSize: 36 }} />
            <div>
              <Typography fontWeight={"bold"}>Goed bereikbaar</Typography>
              <Typography level={"body-sm"}>
                Deze school is goed bereikbaar met het OV
              </Typography>
            </div>
          </div>
          <Divider />
          <Typography level={"h3"}>Beschrijving</Typography>
          <Typography level={"body-md"} sx={{ whiteSpace: "break-spaces" }}>
            {course.description}
          </Typography>
          <Divider />
        </Card>
        <Card
          elevation={10}
          variant={"outlined"}
          orientation={"vertical"}
          style={{ height: "fit-content", bottom: -5 }}
          sx={{
            width: { xs: "100%", md: 300 },
            position: { xs: "fixed", md: "relative" },
            left: { xs: "0px" },
            boxShadow: (theme) => theme.shadow["xl"],
            zIndex: { xs: 99999 },
          }}
        >
          <Typography>{course.name}</Typography>
          <div>
            <Input
              sx={{ "& input": { textAlign: "center" } }}
              style={{ borderRadius: "5px 5px 0 0", width: "100%" }}
              value={new Date(course.date).toLocaleDateString()}
              readOnly={true}
              startDecorator={<CalendarMonth />}
            />
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Input
                sx={{ "& input": { textAlign: "center" } }}
                style={{ borderRadius: "5px 0 0 0", width: "100%" }}
                value={new Date(course.date).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
                readOnly={true}
                startDecorator={<AccessTime />}
              />
              <Input
                sx={{ "& input": { textAlign: "center" } }}
                style={{ borderRadius: "0 5px 0 0", width: "100%" }}
                value={new Date(course.end_date).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
                readOnly={true}
                endDecorator={<AccessTime />}
              />
            </div>
            <Input
              sx={{ "& input": { textAlign: "center" } }}
              style={{ borderRadius: "0 0 5px 5px" }}
              readOnly={true}
              value={
                (
                  Math.abs(new Date(course.date) - new Date(course.end_date)) /
                  36e5
                ).toFixed(2) + " uur"
              }
            />
          </div>
          <Typography>
            <Typography variant="outlined" color="success" fontWeight={"bold"}>
              {course.payment_multiplier}x
            </Typography>{" "}
            je uurloon
          </Typography>
          <Button
            color={
              loading ? "primary" : applied?.applied ? "danger" : "success"
            }
            loading={loading}
            variant={"solid"}
            startDecorator={applied?.applied ? <Remove /> : <Add />}
            disabled={course.status === "dicht"}
            onClick={() => applyForCourse()}
          >
            {applied?.applied ? "Afmelden" : "Aanmelden"}
          </Button>
          {applied?.applied && (
            <Button
              onClick={() =>
                (window.location.href = "/uitnodiging/" + course.id)
              }
              color={applied?.accepted ? "success" : "primary"}
              variant={"outlined"}
            >
              Status
            </Button>
          )}
        </Card>
      </div>
      <Divider orientation={"horizontal"} sx={{ mt: 1, mb: 1 }} />
    </div>
  );
};
