/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Fragment, useEffect, useState } from "react";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Chip from "@mui/joy/Chip";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Link from "@mui/joy/Link";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalClose from "@mui/joy/ModalClose";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import Checkbox from "@mui/joy/Checkbox";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import BlockIcon from "@mui/icons-material/Block";
import { getSchools } from "../../../actions/school-calls";
import { getCoursesForAdmin } from "../../../actions/course-calls";
import { Link as ReactLink } from "react-router-dom";

import { HourglassBottomRounded, Person } from "@mui/icons-material";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const parseDate = (date) => {
  return (
    new Date(date).toLocaleDateString("nl-NL") +
    " " +
    new Date(date).toLocaleTimeString("nl-NL").substring(0, 5)
  );
};

export const ApplicantCourseSelector = () => {
  const [order, setOrder] = useState("desc");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [schools, setSchools] = useState([]);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    getSchools(setSchools);
    getCoursesForAdmin(setCourses);
  }, []);

  const [courseFilter, setCourseFilter] = useState("");
  const [schoolFilter, setSchoolFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [sortedCourses, setSortedCourses] = useState([]);
  useEffect(() => {
    let newCourses = [...courses];
    for (let i = courses.length - 1; i >= 0; i--) {
      let course = courses[i];
      if (
        statusFilter &&
        statusFilter !== "all" &&
        course.status !== statusFilter
      ) {
        newCourses.splice(i, 1);
        continue;
      }
      if (
        schoolFilter &&
        schoolFilter !== "all" &&
        course.school.name !== schoolFilter
      ) {
        newCourses.splice(i, 1);
        continue;
      }
      if (courseFilter && !course.name.includes(courseFilter)) {
        newCourses.splice(i, 1);
      }
    }
    setSortedCourses(newCourses);
  }, [courseFilter, statusFilter, schoolFilter]);

  let rows = courses;
  if (courseFilter || statusFilter || schoolFilter) {
    rows = sortedCourses;
  }

  const renderFilters = () => (
    <React.Fragment>
      <FormControl size="sm">
        <FormLabel style={{ color: "#272D2D" }}>Status</FormLabel>
        <Select
          size="sm"
          placeholder="Alles"
          value={statusFilter}
          onChange={(e, value) => setStatusFilter(value)}
          slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
        >
          <Option value="all">Alles</Option>
          <Option value="open">Open</Option>
          <Option value="wachtrij">Wachtrij</Option>
          <Option value="dicht">Dicht</Option>
        </Select>
      </FormControl>
      <FormControl size="sm">
        <FormLabel style={{ color: "#272D2D" }}>School</FormLabel>
        <Select
          size="sm"
          placeholder="Elke"
          value={schoolFilter}
          onChange={(e, value) => setSchoolFilter(value)}
        >
          <Option value="all">Elke</Option>
          {schools.map((school) => {
            return <Option value={school.name}>{school.name}</Option>;
          })}
        </Select>
      </FormControl>
    </React.Fragment>
  );
  return (
    <div
      style={{
        width: "calc(100vw - 356px)",
        left: 256,
        padding: 50,
        position: "relative",
      }}
    >
      <Fragment>
        <Box
          className="SearchAndFilters-tabletUp"
          sx={{
            borderRadius: "sm",
            py: 2,
            display: { xs: "none", sm: "flex" },
            flexWrap: "wrap",
            gap: 1.5,
            "& > *": {
              minWidth: { xs: "120px", md: "160px" },
            },
          }}
        >
          <FormControl sx={{ flex: 1 }} size="sm">
            <FormLabel style={{ color: "#272D2D" }}>Zoek een les</FormLabel>
            <Input
              value={courseFilter}
              onChange={(e) => setCourseFilter(e.target.value)}
              size="sm"
              placeholder="Zoeken"
              startDecorator={<SearchIcon />}
            />
          </FormControl>
          {renderFilters()}
        </Box>
        <Sheet
          className="OrderTableContainer"
          variant="outlined"
          color={"neutral"}
          sx={{
            display: { xs: "none", sm: "block" },
            width: "100%",
            borderRadius: "sm",
            flexShrink: 1,
            overflow: "auto",
            maxHeight: "75vh",
            minHeight: "75vh",
          }}
        >
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            hoverRow
            sx={{
              "--TableCell-headBackground":
                "var(--joy-palette-background-level1)",
              "--Table-headerUnderlineThickness": "1px",
              "--TableRow-hoverBackground":
                "var(--joy-palette-background-level1)",
              "--TableCell-paddingY": "4px",
              "--TableCell-paddingX": "8px",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    width: 48,
                    textAlign: "center",
                    padding: "12px 6px",
                  }}
                >
                  <Checkbox
                    size="sm"
                    indeterminate={
                      selected.length > 0 && selected.length !== rows.length
                    }
                    checked={selected.length === rows.length}
                    onChange={(event) => {
                      setSelected(
                        event.target.checked ? rows.map((row) => row.id) : []
                      );
                    }}
                    color={
                      selected.length > 0 || selected.length === rows.length
                        ? "primary"
                        : undefined
                    }
                    sx={{ verticalAlign: "text-bottom" }}
                  />
                </th>
                <th style={{ width: 120, padding: "12px 6px" }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => setOrder(order === "asc" ? "desc" : "asc")}
                    fontWeight="lg"
                    endDecorator={<ArrowDropDownIcon />}
                    sx={{
                      "& svg": {
                        transition: "0.2s",
                        transform:
                          order === "desc" ? "rotate(0deg)" : "rotate(180deg)",
                      },
                    }}
                  >
                    Les
                  </Link>
                </th>
                <th style={{ width: 140, padding: "12px 6px" }}>Datum</th>
                <th style={{ width: 100, padding: "12px 6px" }}>Status</th>
                <th style={{ width: 100, padding: "12px 6px" }}>
                  Aanmeldingen
                </th>
                <th style={{ width: 180, padding: "12px 6px" }}>School</th>
                <th style={{ width: 180, padding: "12px 6px" }}>Docent</th>
                <th style={{ width: 140, padding: "12px 6px" }}></th>
              </tr>
            </thead>
            <tbody>
              {stableSort(rows, getComparator(order, "id")).map((row) => (
                <tr key={row.id}>
                  <td style={{ textAlign: "center", width: 120 }}>
                    <Checkbox
                      size="sm"
                      checked={selected.includes(row.id)}
                      color={selected.includes(row.id) ? "primary" : undefined}
                      onChange={(event) => {
                        setSelected((ids) =>
                          event.target.checked
                            ? ids.concat(row.id)
                            : ids.filter((itemId) => itemId !== row.id)
                        );
                      }}
                      slotProps={{ checkbox: { sx: { textAlign: "left" } } }}
                      sx={{ verticalAlign: "text-bottom" }}
                    />
                  </td>
                  <td>
                    <Typography level="body-xs">{row.name}</Typography>
                  </td>
                  <td>
                    <Typography level="body-xs">
                      {parseDate(row.date)}
                    </Typography>
                  </td>
                  <td>
                    <Chip
                      variant="soft"
                      size="sm"
                      startDecorator={
                        {
                          open: <CheckRoundedIcon />,
                          wachtrij: <HourglassBottomRounded />,
                          dicht: <BlockIcon />,
                        }[row.status]
                      }
                      color={
                        {
                          open: "success",
                          wachtrij: "warning",
                          dicht: "danger",
                        }[row.status]
                      }
                    >
                      {row.status}
                    </Chip>
                  </td>
                  <td>
                    <Avatar size="sm">{row.applicants}</Avatar>
                  </td>
                  <td>
                    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                      <Avatar
                        size="sm"
                        src={row.school.school_picture}
                      ></Avatar>
                      <div>
                        <Typography level="body-xs">
                          {row.school.name}
                        </Typography>
                        <Typography level="body-xs">
                          {row.school.type}
                        </Typography>
                      </div>
                    </Box>
                  </td>
                  <td>
                    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                      {row.teacher ? (
                        <>
                          <Avatar size="sm" src={row.teacher.profile_picture}>
                            {row.teacher &&
                            row.teacher.name &&
                            !row.teacher.profile_picture ? (
                              row.teacher.name[0]
                            ) : row.teacher.name ? (
                              ""
                            ) : (
                              <Person />
                            )}
                          </Avatar>
                          <div>
                            <Typography level="body-xs">
                              {row.teacher.name}
                            </Typography>
                            <Typography level="body-xs">
                              {row.teacher.email}
                            </Typography>
                          </div>
                        </>
                      ) : (
                        "Open"
                      )}
                    </Box>
                  </td>
                  <td>
                    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                      <Link
                        level="body-xs"
                        component={ReactLink}
                        to={"/dashboard/courses/applicants/" + row.id}
                      >
                        <Button variant={"outlined"} color={"primary"}>
                          Aanmeldingen
                        </Button>
                      </Link>
                    </Box>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Sheet>
      </Fragment>
    </div>
  );
};
