import { useEffect, useState, useRef } from "react";
import { findCourses, getCourses } from "../../actions/course-calls";
import { Typography } from "@mui/joy";
import { CourseOverviewCard } from "../cards/CourseOverviewCard";
import { DrawerFilters } from "../utils/DrawerFilters";

export const Courses = () => {
  const [courses, setCourses] = useState([]);
  const [courseType, setCourseType] = useState("alles");
  const [qualification, setQualification] = useState("alles");
  const [days, setDays] = useState([0, 1, 2, 3, 4]);
  const [dayParts, setDayParts] = useState([0, 1, 2]);
  const [maxDistance, setMaxDistance] = useState(5);
  const [accountPreferences, setAccountPreferences] = useState(false);
  const [resultCount, setResultCount] = useState(0);
  const [searchCourses, setSearchCourses] = useState([]);
  const [sort, setSort] = useState("date");
  const [sortType, setSortType] = useState("asc");
  useEffect(() => {
    findCourses(
      courseType,
      qualification,
      days,
      dayParts,
      maxDistance,
      accountPreferences,
      (newCourses) => {
        console.log("fetching courses", newCourses.length);
        setSearchCourses(newCourses);
        setResultCount(newCourses.length);
        setCourses(newCourses);
      }
    );
  }, [
    courseType,
    qualification,
    days,
    dayParts,
    maxDistance,
    accountPreferences,
  ]);

  useEffect(() => {
    let newCourses;
    if (courses.length === 0) return;
    const customSort = (a, b) => {
      if (sort === "school") {
        if (sortType === "asc") {
          return a.school.name.localeCompare(b.school.name);
        } else {
          return b.school.name.localeCompare(a.school.name);
        }
      } else if (sort === "application_status") {
        if (sortType === "asc") {
          return a.applied < b.applied ? -1 : 1;
        } else {
          return b.applied < a.applied ? -1 : 1;
        }
      } else {
        if (sortType === "asc") {
          return a[sort] < b[sort] ? -1 : 1;
        } else {
          return a[sort] > b[sort] ? -1 : 1;
        }
      }
    };
    newCourses = [...courses].sort(customSort);
    if (
      JSON.stringify(newCourses.map((c) => c.id)) !==
      JSON.stringify(courses.map((c) => c.id))
    )
      setCourses(newCourses);
  }, [sort, sortType, courses]);

  useEffect(() => {
    findCourses(
      courseType,
      qualification,
      days,
      dayParts,
      maxDistance,
      accountPreferences,
      (courses) => {
        setSearchCourses(courses);
        setResultCount(courses.length);
      }
    );
  }, []);
  return (
    <div className={"courses-container"}>
      <Typography level={"h1"} sx={{ color: "#272D2D" }}>
        Lessen
      </Typography>
      <DrawerFilters
        courseType={courseType}
        setCourseType={setCourseType}
        setQualification={setQualification}
        qualification={qualification}
        setDayParts={setDayParts}
        days={days}
        dayParts={dayParts}
        setDays={setDays}
        resultCount={resultCount}
        maxDistance={maxDistance}
        setMaxDistance={setMaxDistance}
        accountPreferences={accountPreferences}
        sort={sort}
        setAccountPreferences={setAccountPreferences}
        search={() => {
          setCourses(searchCourses);
        }}
        sortType={sortType}
        setSort={setSort}
        setSortType={setSortType}
      />
      <CourseOverviewCard courses={courses} />
    </div>
  );
};
