import { DelphiAPIClient } from "./axios-instance";
import { keys } from "../config/keys.dev";

export const getSchools = async (setSchools) => {
  const response = await DelphiAPIClient.get(keys.url + "/api/get_schools/");
  setSchools(response.data);
};

export const getSchool = async (schoolId, setSchool) => {
  const response = await DelphiAPIClient.get(
    keys.url + `/api/get_school/${schoolId}/`
  );
  setSchool(response.data);
};

export const getAdminSchool = async (setSchool) => {
  const response = await DelphiAPIClient.get(
    keys.url + `/api/get_admin_school/`
  );
  setSchool(response.data);
};
